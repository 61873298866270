import _chunk from 'lodash/chunk';

/**
 * Processes an array in chunks with a given async function.
 *
 * @param {Array} array - The array to process.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {Promise<Array>} - A promise that resolves to an array of results.
 */
export const processInChunks = async (array, chunkSize) => {
  const chunkedArray = _chunk(array, chunkSize);
  const results = [];

  // Process chunks sequentially
  for (const chunk of chunkedArray) {
    const chunkResults = await Promise.allSettled(chunk);
    results.push(...chunkResults);
  }

  return results;
};
