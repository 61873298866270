import { getDataNoAuth, putData } from '../utils/fetch-api';
import { processInChunks } from '../utils/batch-async';

const baseSlsUrl = `${process.env.REACT_APP_BASE_API_URL}`;

export const publishPhoto = (photoId) => {
  return putData(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/photos/${photoId}/publish`, {}, true);
};

export const getGalleryElements = (galleryId) =>
    getDataNoAuth(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/galleries/${galleryId}`);

export const publishGallery = async (galleryId) => {
  try {
    const photoElements = await getGalleryElements(galleryId);
    const unpublishedPhotos = photoElements?.content_elements?.filter(photo => photo?._id && !photo?.additional_properties?.published) || [];

    if (unpublishedPhotos?.length > 0) {
      const publishGalleryPhotos = unpublishedPhotos.map(photo => publishPhoto(photo?._id));

      await processInChunks(publishGalleryPhotos, 5);
    }
  } catch (e) {
    console.error('Error publishing photos of gallery: ', e);
  }

  return putData(`${baseSlsUrl}arc-content/arc-proxy/photo/api/v2/galleries/${galleryId}/publish`, {}, true);
};
