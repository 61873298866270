import React, { Component } from 'react';

import _ from 'lodash';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { _buildRootTopic } from '../../utils/common';

import { withContext } from './NotificationContext';
import { CONSTANTS, handleCountTopicSelected } from '../../utils/common';
import i18n from './messages.json';

class TopicPicker extends Component {

  _rootTopic = null;

  onChange = node => {
    return event => {
      event.target.checked ? node.select() : node.deselect();
      this.forceUpdate(); // this is required since we are updating the context
      const numberTopicSelected = handleCountTopicSelected(this._rootTopic.children);

      try {
        this._rootTopic.children.forEach(child => numberTopicSelected >= CONSTANTS.max_topic_num ? child.disable(true) : child.unDisable(true));
      } catch (e) {
        console.error(e);
      }
    }
  }

  handleCancel = () => {
    this.props.onHide();
    this._cleanUp();
  };

  handleDone = () => {
    const {updateState} = this.props.context;
    updateState({rootTopic: this._rootTopic});

    _.defer(() => {
      this._cleanUp();
      this.props.onDone();
    });
  }

  _cleanUp() {
    this._rootTopic = null;
  }

  render() {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={'static'}
        onHide={this.props.onHide}
        show={this.props.show}
        size='sm'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Topic Picker
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderTopics()}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={this.handleCancel}>Cancel</Button>
          <Button variant="secondary" onClick={this.handleDone}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  _renderTopics = () => {
    if (!this._rootTopic) {
      const {sectionList, rootTopic} = {...this.props.context};
      if(this.props.context.rootTopic.children.length === 0) {
        this._rootTopic = _buildRootTopic(sectionList);
      } else {
        // always working on a copy
        this._rootTopic = _.cloneDeep(rootTopic);
      }
    }

    if(!this._rootTopic) return <div>{i18n.ink.notification.loading}</div>;

    const style = {
      maxHeight: 500,
      overflowY: 'scroll',
    }

    let items = _.map(this._rootTopic.children, (option, idx) => this._buildMenuItems(option, 1, idx + 1));
    items = _.compact(items);

    return <div style={style}>{items}</div>;
  };

  _buildMenuItems = (treenode, level, idx) => {
    let node = this._buildMenuItem(treenode, level, idx);
    if(!node) return null;

    const items = treenode.children.map((option, index) => {
      return this._buildMenuItems(option, level + 1, (100 * idx) + index);
    });
    return [node].concat(items);
  }

  _buildMenuItem = (node, level, idx) => {
    if(level > CONSTANTS.topic_hierarchy_max_level) return null;

    const style = {
      paddingLeft:  `${level * 1.5}em`,
      fontSize:     `${1 - level * 0.1}em`,
      marginBottom: '-1.4em',
    }

    return (
      <div
        style={style}
        key={'div-' + idx}
      >
        <Form.Group controlId="formBasicChecbox">
          <Form.Check
            id={'cb-' + idx}
            type="checkbox"
            checked={node.selected}
            disabled={node.disabled}
            onChange={this.onChange(node)}
            label={node.label}/>
        </Form.Group>
      </div>
    );
  }
}

export default withContext(TopicPicker);
