import _find from 'lodash/find';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import { ILiveBlogCard } from '../types/component-type';
import { getCardHeaderHeadlineElement, getCardHeaderDescriptionElement } from './card';
import StoryMetadata from '../types/metadata';
import { getHtmlText } from '../../../utils/string-helper';

const fusionBase = `https://${ process.env.REACT_APP_PROJECT_ENV !== "prod" ? "stage.fusion." : ""}inquirer.com`;

export const getThumbnailImageUrl = (photo) => {
  return `${fusionBase}${photo?.additional_properties?.thumbnailResizeUrl || photo?.additional_properties?.fullSizeResizeUrl}` || photo?.url;
}

export const getArcComposerUrl = (articleId) => `https://${ process.env.REACT_APP_PROJECT_ENV !== "prod" ? "sandbox." : "" }pmn.arcpublishing.com/composer/edit/${articleId}/`;

export const END_LIVEBLOG_TAG = {
  description: "endliveblog",
  slug: "endliveblog",
  text: "endliveblog"
};

export const hasEndLiveblogTag = (article) => article?.taxonomy?.tags?.some((tag) => tag?.slug === END_LIVEBLOG_TAG.slug || tag?.label === END_LIVEBLOG_TAG.slug);

export const getTimeStampItem = (data: ILiveBlogCard) => (
  _find(data?.elements || [], element => (typeof element?.content === 'string' ?  element?.content : '')?.includes('// Timestamp'))
)

export const getPinnedItem = (data: ILiveBlogCard) => (
  _find(data?.elements || [], element => (typeof element?.content === 'string' ?  element?.content : '')?.includes('// Pinned'))
)

export const getImageSrc = (imageData: IArticleContentElement): string => {
  return imageData?.additional_properties?.published
    ? imageData?.url
    : `${fusionBase}${imageData?.additional_properties?.fullSizeResizeUrl}`;
};


export const getUpdatedHeaderDetails = (workingArticle, workingCard, isNestedObject, fullContent) => {
  const basicHeadlineElement = getCardHeaderHeadlineElement(workingCard?.elements, workingArticle?._id);
  const basicDescriptionElement = getCardHeaderDescriptionElement(workingCard?.elements,  workingArticle?._id);
  const featuredImageElement = _find(workingCard?.elements, (element) => element?.referent?.type === 'image');

  const updatedHeader = _omitBy({
    'headlines.basic': getHtmlText(basicHeadlineElement?.content) || '',
    'description.basic': getHtmlText(basicDescriptionElement?.content) || '',
    'promo_items.basic': featuredImageElement?.referent || fullContent?.promo_items?.basic,
    'label.commission_team_2': workingArticle?.[StoryMetadata.Team2] || fullContent?.label?.commission_team_2,
    'slug': workingArticle?.[StoryMetadata.Slug] || fullContent?.slug,
    'credits.by': workingArticle?.[StoryMetadata.Credits] || fullContent?.credits?.by,
    'label.commission_team_1_required': workingArticle?.[StoryMetadata.Team1] || fullContent?.label?.commission_team_1_required,
    'headlines.meta_title': workingArticle?.[StoryMetadata.HeadlinesMetaTitle] || fullContent?.headlines?.meta_title,
    'headlines.web': workingArticle?.[StoryMetadata.HeadlinesWeb] || fullContent?.headlines?.web,
    'taxonomy.seo_keywords': workingArticle?.[StoryMetadata.SeoKeywords] || fullContent?.taxonomy?.seo_keywords,
    'taxonomy.tags': workingArticle?.[StoryMetadata.Tags] || fullContent?.taxonomy?.tags,
  }, _isNil);

  if (!isNestedObject) return updatedHeader;

  const nestedObject = {};

  _forEach(updatedHeader, (value, key) => {
    _set(nestedObject, key, value);
  });

  return nestedObject;
};
