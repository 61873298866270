import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
import _some from 'lodash/some';
import CardTypes from '../types/card-type';
import { ILiveBlogCard } from '../types/component-type';
import { IArticleContentElement } from '../types/arc-article';
import { getTimeStampItem } from './story';
import { getHtmlText } from '../../../utils/string-helper';

export const getCardElementLabel = (element) => {
  const referentType = element?.referent?.type;

  if (referentType) return _startCase(referentType);

  switch (element?.type) {
    case 'text':
      return 'Body text';
    case 'image':
      return 'Image';
    case 'interstitial_link':
      return 'Interstitial Link';
    case 'list':
      return 'List';
    case 'raw_html':
      return 'Raw HTML';
    default:
      return 'Unknown';
  }
};

export const splitter = (elements: IArticleContentElement[]): ILiveBlogCard[] => {
  if (elements.length === 0) {
    return [];
  }

  const results: ILiveBlogCard[] = [];
  let group: IArticleContentElement[] = [];

  elements.forEach((element, index) => {
    if (element.type === 'divider' || index === elements.length - 1) {
      results.push({ elements: group, id: group?.[0]?._id, type: CardTypes.Content });
      group = [];
    } else {
      if (group.length === 0 && element.type === 'list') {
        results.push({ elements: [element], id: element._id, type: CardTypes.List });
        group = [];
      } else {
        group.push(element);
      }
    }
  });

  return results.filter(item => item?.id && item.elements?.length > 0);
};

export const articleHeaderCardId = (articleId: string) => `header-card-${articleId}`;
export const basicHeadlineId = (articleId: string) => `basic-headlines-${articleId}`;
export const basicDescriptionId = (articleId: string) => `basic-description-${articleId}`;

export const getCardHeaderHeadlineElement = (elements: IArticleContentElement[], articleId: string) => {
  return elements.find((element) => element?._id === basicHeadlineId(articleId));
};

export const getCardHeaderDescriptionElement = (elements: IArticleContentElement[], articleId: string) => {
  return elements.find((element) => element?._id === basicDescriptionId(articleId));
};

export const getPublishedCardIds = (cardStatuses) => {
  return cardStatuses?.reduce((acc, cardStatus) => {
    if (cardStatus?.published_at) return [...acc, cardStatus.cardId];

    return acc;
  }, []);
};

export const isValidCard = (card) => {
  const elements = card?.elements || [];

  if (card?.type === CardTypes.MiniNav) {
    const hasHeadline = _find(elements, (element) => element?.type === 'header' && element?.content?.length);
    const hasEmptyContent = _some(elements, (element) => element?.type === 'text' && (!element?.url?.length || !element?.text?.length));

    return hasHeadline && !hasEmptyContent;
  }

  if (card?.type === CardTypes.Content) {
    const hasHeadline = _some(elements, (element) => element?.type === 'header' && element?.content?.length);
    const cardTimestamp = getTimeStampItem(card);
    const hasEmptyElement = _some(elements, (element) => {
      const { type, content } = element;
      const hasEmptyText = type === 'text' && !getHtmlText(content)?.length;
      const isInvalidList = type === 'list' && (!element?.items?.length || _some(element?.items, (item) => !getHtmlText(item?.content)?.length));
    
      return hasEmptyText || isInvalidList;
    });

    return hasHeadline && cardTimestamp && !hasEmptyElement;
  }

  if (card?.type === CardTypes.List) {
    const hasEmptyElement = _some(elements?.[0]?.items || [],
      (element) => element?.type === 'text' && !getHtmlText(element?.content)?.length
    );
    return !hasEmptyElement;
  }

  if (isInteractiveHTMLCard(card?.type)) {
    return _some(elements, (element) => element?.type === 'raw_html');
  }

  return false;
};

export const isInteractiveHTMLCard = (type?: string): boolean => type === CardTypes.InteractiveHTML;
